import { styled } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { Suspense, useMemo } from 'react';
import { createNetworkStatusNotifier, NetworkStatus } from 'react-apollo-network-status';
import Helmet from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Provider as APIProvider } from 'src/api/provider';
import {
    DEFAULT_SEGMENT_WRITE_KEY,
    OPS_SEGMENT_WRITE_KEY,
    ORC_SEGMENT_WRITE_KEY,
    PMC_SEGMENT_WRITE_KEY,
    RMC_SEGMENT_WRITE_KEY,
} from 'src/constants/environment';
import { ACTION_SCHEDULE_WORK_ORDER } from 'src/constants/loginTokenActions';
import { FONT_URL } from 'src/constants/ui';
import { AccountType } from 'src/graphql/client';
import { SessionDetailsFragment } from 'src/graphql/lib';
import { useAccessToken } from 'src/helpers/accessToken';
import { GraphqlProvider } from 'src/helpers/graphql';
import { qsParse, qsStringify } from 'src/helpers/query-string';
import { AnalyticsProvider } from 'src/lib/analytics';
import { AnalyticsContextProvider } from 'src/lib/analytics/context';
import {
    AnalyticsAccountType,
    AnalyticsEventSource,
    AnalyticsProductWorkflow,
    AnalyticsSource,
} from 'src/lib/analytics/events';
import { ThemeProvider } from 'src/lib/style';
import { baseTheme } from 'src/theme';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { FacilityAppSSO } from './Auth/FacilityAppSSO';
import { MagicLink } from './Auth/MagicLink';
import SingleSignOn from './Auth/SingleSignOn';
import ForgotPassword from './ForgotPassword';
import Impersonate from './Impersonate';
import QRCodeLanding from './QRCodeLanding';
import { ResQRouter } from './ResQRouter';
import ResetPassword from './ResetPassword';
import { SignOut } from './SignOut';
import VendorLinkInvalid from './VendorLinkInvalid';
import { BannerProvider } from './bannerContext';
import Loader from './components/atoms/Loader';
import SentryUserContext from './components/domain/SentryUserContext';
import { ConfirmLeaveProvider } from './confirmLeaveContext';
import { FeatureFlagsProvider } from './featureFlagsContext';
import { lazyComponentLoader } from './helpers';
import { LocaleProvider } from './localeContext';
import { NavBarProvider } from './navBarContext';
import { SessionProvider, useNullableSessionUser } from './sessionContext';
import { SnackbarProvider } from './snackbar';

const VendorScheduling = lazyComponentLoader(() => import('./VendorScheduling'));
const CoordinatorDashboard = lazyComponentLoader(() => import('./CoordinatorDashboard'));
const ClientDashboard = lazyComponentLoader(() => import('./ClientDashboard'));
const VendorDashboard = lazyComponentLoader(() => import('./VendorDashboard'));
const MissingOrganizationPage = lazyComponentLoader(() => import('./MissingOrganizationPage'));
const Auth = lazyComponentLoader(() => import('./Auth'));
const OffResqCustomerPortal = lazyComponentLoader(() => import('./OffResqCustomer'));

interface PlatformResult {
    platform: React.LazyExoticComponent<React.ComponentType<any> | React.FC> | null;
    analyticsSource: AnalyticsSource;
    productWorkflow: AnalyticsProductWorkflow | null;
    writeKey: string;
    accountType: AnalyticsAccountType | undefined;
}

const usePlatform = (me: SessionDetailsFragment | null, accessToken: any): PlatformResult => {
    const offResQCustomerMatch = useRouteMatch('/fast-pay-customer');
    const exceptionRouteMatch = useRouteMatch([
        '/accept-invite',
        '/decline-invite',
        '/invite-already-accepted',
    ]);

    return useMemo((): PlatformResult => {
        if (accessToken?.action === ACTION_SCHEDULE_WORK_ORDER) {
            return {
                platform: VendorScheduling,
                analyticsSource: AnalyticsSource.APPLESS_SCHEDULING,
                productWorkflow: null,
                writeKey: PMC_SEGMENT_WRITE_KEY,
                accountType: AnalyticsAccountType.PMC,
            };
        }

        if (offResQCustomerMatch) {
            return {
                platform: OffResqCustomerPortal,
                analyticsSource: AnalyticsSource.OFF_RESQ_CUSTOMER_PORTAL,
                productWorkflow: null,
                writeKey: ORC_SEGMENT_WRITE_KEY,
                accountType: undefined,
            };
        }

        if (me && !exceptionRouteMatch) {
            if ([AccountType.Coordinator, AccountType.ControlCenter].includes(me.accountType)) {
                return {
                    platform: CoordinatorDashboard,
                    analyticsSource: AnalyticsSource.RCC,
                    productWorkflow: null,
                    writeKey: OPS_SEGMENT_WRITE_KEY,
                    accountType: AnalyticsAccountType.RCC,
                };
            }

            if (me.accountType === AccountType.Client) {
                return {
                    platform: ClientDashboard,
                    analyticsSource: AnalyticsSource.RMC,
                    productWorkflow: null,
                    writeKey: RMC_SEGMENT_WRITE_KEY,
                    accountType: AnalyticsAccountType.RMC,
                };
            }

            if (me.accountType === AccountType.Vendor) {
                return {
                    platform: VendorDashboard,
                    analyticsSource: AnalyticsSource.PMC,
                    productWorkflow: null,
                    writeKey: PMC_SEGMENT_WRITE_KEY,
                    accountType: AnalyticsAccountType.PMC,
                };
            }

            if (me.accountType === AccountType.OffPlatform) {
                return {
                    platform: OffResqCustomerPortal,
                    analyticsSource: AnalyticsSource.OFF_RESQ_CUSTOMER_PORTAL,
                    productWorkflow: null,
                    writeKey: PMC_SEGMENT_WRITE_KEY,
                    accountType: AnalyticsAccountType.OFF_RESQ_CUSTOMER_PORTAL,
                };
            }

            if (me.accountType === AccountType.Independent) {
                return {
                    platform: MissingOrganizationPage,
                    analyticsSource: AnalyticsSource.INDEPENDENT,
                    productWorkflow: null,
                    writeKey: PMC_SEGMENT_WRITE_KEY,
                    accountType: AnalyticsAccountType.INDEPENDENT,
                };
            }
        }

        return {
            platform: Auth,
            analyticsSource: AnalyticsSource.LOGIN,
            productWorkflow: AnalyticsProductWorkflow.Login,
            writeKey: DEFAULT_SEGMENT_WRITE_KEY,
            accountType: undefined,
        };
    }, [accessToken, me, offResQCustomerMatch, exceptionRouteMatch]);
};

const Routes = React.memo(() => {
    const me = useNullableSessionUser();
    const accessToken = useAccessToken();

    const { platform, analyticsSource, productWorkflow, writeKey, accountType } = usePlatform(
        me,
        accessToken,
    );

    return (
        <AnalyticsContextProvider
            value={{
                application: analyticsSource,
                eventSource: AnalyticsEventSource.FRONTEND,
                productWorkflow,
            }}
        >
            <AnalyticsProvider segmentWriteKey={writeKey} accountType={accountType}>
                <Switch>
                    <Route path="/sign-out" component={SignOut} />
                    <Route path="/impersonate/:userId" component={Impersonate} />
                    <Route path="/qrcode" component={QRCodeLanding} />
                    <Route path="/invalid-link" component={VendorLinkInvalid} />
                    <Route path="/reset-password/:userId/:token" component={ResetPassword} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route path="/password-free-link" component={MagicLink} />
                    <Route path="/facility-app-sso" component={FacilityAppSSO} />
                    <Route path="/sso" component={SingleSignOn} />
                    {platform ? (
                        <Route path="/" component={platform} />
                    ) : (
                        <Redirect to="/sign-out" />
                    )}
                </Switch>
            </AnalyticsProvider>
        </AnalyticsContextProvider>
    );
});

const Wrapper = styled('div')``;

type Wrap = (children?: React.ReactNode) => React.ReactElement<any, any> | null;

const wrap = (layers: Wrap[]) => {
    return layers
        .reverse()
        .reduce(
            (children, layerWrap) => layerWrap(children),
            null as React.ReactElement<any, any> | null,
        );
};

const LoaderComponent = ({ apolloNetworkStatus }: { apolloNetworkStatus: NetworkStatus }) => {
    return apolloNetworkStatus.numPendingQueries > 0 ? <Loader /> : null;
};

const queryClient = new QueryClient();

export const App = () => {
    const { link: networkStatusNotifierLink, useApolloNetworkStatus } = useMemo(
        () => createNetworkStatusNotifier(),
        [],
    );

    const apolloNetworkStatus = useApolloNetworkStatus();

    const Wrapped = useMemo(
        () =>
            wrap([
                (children) => <ResQRouter>{children}</ResQRouter>,
                (children) => (
                    <QueryParamProvider
                        adapter={ReactRouter5Adapter}
                        options={{
                            searchStringToObject: qsParse,
                            objectToSearchString: qsStringify,
                        }}
                    >
                        {children}
                    </QueryParamProvider>
                ),
                (children) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {children}
                    </LocalizationProvider>
                ),
                (children) => <ThemeProvider theme={baseTheme}>{children}</ThemeProvider>,
                (children) => <SnackbarProvider>{children}</SnackbarProvider>,
                (children) => <APIProvider>{children}</APIProvider>,
                (children) => (
                    <GraphqlProvider networkStatusNotifierLink={networkStatusNotifierLink}>
                        {children}
                    </GraphqlProvider>
                ),
                (children) => (
                    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
                ),
                (children) => <SessionProvider>{children}</SessionProvider>,
                (children) => <FeatureFlagsProvider>{children}</FeatureFlagsProvider>,
                (children) => <LocaleProvider>{children}</LocaleProvider>,
                (children) => <BannerProvider>{children}</BannerProvider>,
                (children) => <NavBarProvider>{children}</NavBarProvider>,

                () => (
                    <ConfirmLeaveProvider>
                        <SentryUserContext />
                        <Suspense fallback={null}>
                            <Wrapper>
                                <Routes />
                            </Wrapper>
                        </Suspense>
                    </ConfirmLeaveProvider>
                ),
            ]),
        [networkStatusNotifierLink],
    );

    return (
        <>
            <Helmet>
                <link rel="stylesheet" href={FONT_URL} />
            </Helmet>
            <LoaderComponent apolloNetworkStatus={apolloNetworkStatus} />
            {Wrapped}
        </>
    );
};
