import {
    Typography as TypographyBase,
    TypographyProps as TypographyBaseProps,
} from '@mui/material';
import { ThemePalette } from '../style/theme';
import { TypographyRole, useTypography } from './useTypography';

export type TextColor =
    | keyof Omit<ThemePalette['text'], 'getBase' | 'getPressed' | 'getDisabled' | 'getOn'>
    | 'inherit';

export type TypographyProps = Pick<
    TypographyBaseProps,
    'sx' | 'align' | 'noWrap' | 'whiteSpace' | 'component'
> & {
    role: TypographyRole;
    color?: TextColor;
    children: React.ReactNode;
};

export const Typography = ({
    role,
    color = 'default',
    sx,
    noWrap,
    align,
    whiteSpace,
    component = 'div',
    children,
    ...props
}: TypographyProps) => {
    const { fontSize, lineHeight, fontWeight } = useTypography(role);

    return (
        <TypographyBase
            component={component}
            align={align}
            noWrap={noWrap}
            whiteSpace={whiteSpace}
            sx={{
                fontSize,
                lineHeight,
                fontWeight,
                color: (theme) => (color === 'inherit' ? 'inherit' : theme.resqPalette.text[color]),
                ...sx,
            }}
            {...props}
        >
            {children}
        </TypographyBase>
    );
};
