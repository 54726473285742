import { Box, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useAPI } from 'src/api/provider';
import { Button } from 'src/lib/components/Button';
import { TextField } from 'src/lib/components/fields/TextField';
import * as Yup from 'yup';
import Container from '../Auth/components/Container';

interface FormValues {
    password: string;
    passwordConfirmation: string;
}

interface RouteProps {
    userId: string;
    token: string;
}

interface Props extends RouteComponentProps<RouteProps> {}

const ResetPassword = ({ match }: Props) => {
    const api = useAPI();
    const { t } = useTranslation('auth.resetPassword');
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { userId, token } = match.params;
    if (isSubmitted) {
        return (
            <Container width={360}>
                <Typography variant="h2" textAlign="center" marginBottom={2}>
                    {t('passwordHasBeenReset')}
                </Typography>
                <Typography variant="body1" textAlign="center" marginBottom={4}>
                    {t('loginWithNewPassword')}
                </Typography>
                <Box>
                    <RouterLink to="/login">
                        <Button kind="filled" color="primary" fullWidth>
                            {t('returnToLogin')}
                        </Button>
                    </RouterLink>
                </Box>
            </Container>
        );
    }
    return (
        <Container width={360}>
            <Typography variant="h2" textAlign="center" marginBottom={2}>
                {t('chooseNewPassword')}
            </Typography>
            <Typography variant="body1" textAlign="center" marginBottom={4}>
                {t('passwordInstructions')}
            </Typography>
            <Formik<FormValues>
                validationSchema={Yup.object().shape({
                    password: Yup.string().required('Required').min(6, t('passwordTooShort')),
                    passwordConfirmation: Yup.string()
                        .required('Required')
                        .oneOf([Yup.ref('password')], t('passwordsDoNotMatch')),
                })}
                initialValues={{
                    password: '',
                    passwordConfirmation: '',
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        await api.post('/client/reset-password/', {
                            userId,
                            token,
                            password: values.password,
                        });
                        setIsSubmitted(true);
                    } catch (err: any) {
                        enqueueSnackbar(err.message, { variant: 'error' });
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ values, isSubmitting, submitCount, errors, handleChange, handleSubmit }) => {
                    if (submitCount === 0) {
                        errors = {};
                    }
                    return (
                        <form onSubmit={handleSubmit}>
                            <Box marginBottom={4}>
                                <TextField
                                    label={t('newPassword')}
                                    name="password"
                                    type="password"
                                    autoComplete="new-password"
                                    value={values.password}
                                    onChange={handleChange}
                                    error={errors.password}
                                    autoFocus
                                />
                            </Box>
                            <Box marginBottom={4}>
                                <TextField
                                    label={t('confirmNewPassword')}
                                    name="passwordConfirmation"
                                    type="password"
                                    autoComplete="new-password"
                                    value={values.passwordConfirmation}
                                    onChange={handleChange}
                                    error={errors.passwordConfirmation}
                                />
                            </Box>
                            <Box marginBottom={2}>
                                <Button
                                    type="submit"
                                    kind="filled"
                                    color="primary"
                                    fullWidth
                                    disabled={
                                        isSubmitting ||
                                        !values?.password?.trim() ||
                                        !values?.passwordConfirmation?.trim()
                                    }
                                >
                                    {t('resetPassword')}
                                </Button>
                            </Box>
                        </form>
                    );
                }}
            </Formik>
            <Box>
                <RouterLink to="/login">
                    <Button kind="filled" color="secondary" fullWidth>
                        {t('returnToLogin')}
                    </Button>
                </RouterLink>
            </Box>
        </Container>
    );
};

export default withRouter(ResetPassword);
